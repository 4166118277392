import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '62, 39, 35',
		'primary-dark': '30, 19, 17',
		'accent': '190, 98, 41',
		'accent-plus': '255, 255, 255',
	},
});
